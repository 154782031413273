import React, { CSSProperties } from "react";

type GridDataDisplayLinePropType = {
  line: Array<Array<string>>;
};

const GridDataDisplayLine = ({ line }: GridDataDisplayLinePropType) => {
  return (
    <div>
      <div className="dataLine">
        {line.map((entry) => (
          <div className="dataEntryContainer">
            <span className={"label"}>{entry[0]}</span>
            {entry[1]}
          </div>
        ))}
      </div>
    </div>
  );
};

type GridDataDisplayPropType = {
  data: Array<Array<Array<string>>>;
};

const GridDataDisplay = ({ data }: GridDataDisplayPropType) => {
  return (
    <div>
      <div className={"dataArea"}>
        {data.map((line) => (
          <GridDataDisplayLine line={line} />
        ))}
      </div>
    </div>
  );
};

export default GridDataDisplay;
