import React, { useEffect, useState } from "react";
import WaitingAnimation from "../WaitingAnimation";
import GridDataDisplay from "../GridDataDisplay";
import ChangePassword from "./ChangePassword";
import { urlService } from "../../_services/urlService";

const Profile = () => {
  type gridDataType = Array<Array<Array<string>>>;

  type stateType = {
    is_waiting: boolean;
    gridData: gridDataType;
  };

  type UserResponseType = {
    surname: string;
    name: string;
    email: string;
  };

  const [state, setState] = useState({ is_waiting: true } as stateType);

  useEffect(() => {
    const url = urlService.getUrl("/user/data");

    urlService
      .getAxios()
      .get(url)
      .then((res) => {
        let user = res.data as UserResponseType;
        let gridData = [
          [
            ["Name", user.surname],
            ["Vorname", user.name],
          ],
          [["Email", user.email]],
        ] as gridDataType;
        setState({ is_waiting: false, gridData });
      });
  }, [setState]);

  return (
    <div style={{ width: "100%" }}>
      {state.is_waiting ? <WaitingAnimation /> : <GridDataDisplay data={state.gridData} />}
      <ChangePassword expectOldPassword={true} />
    </div>
  );
};

export default Profile;
